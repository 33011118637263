import React from 'react'

import {
  TextField,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

import Select from 'react-select'
import { Alert, Button } from 'reactstrap'
import { injectIntl, FormattedMessage } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'
import * as reportsActions from '@store/actions/reports'

import { getAllProjects, getAllServers } from '@utils/helpers/get'
import { useDebounce } from '@utils/hooks/useDebounce'
import { formatStringDate } from '@utils/util'
import * as actionTypes from "@store/actions/actionTypes"

const Report = injectIntl((props) => {
  const dispatch = useDispatch()

  const {
    dates, projects, jiraServers, jiraState, jiraServerAuthorized, report, plan, isSaved
  } = useSelector((state) => state.rep)
  const { error, isFetching } = useSelector((state) => state.global)

  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedProjectId, setSelectedProjectId] = React.useState(null)
  const [selectedJiraServerId, setSelectedJiraServerId] = React.useState(null)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false)
  const [popupData, setPopupData] = React.useState(null)

  const prevPropsRef = React.useRef(jiraServers)

  const token = props.match.params.token || null

  React.useEffect(() => {
    dispatch(reportsActions.loadReportInfo(token))
  }, [])

  const onServerSelected = (serverId) => {
    setSelectedJiraServerId(serverId)
    dispatch({
      type: actionTypes.SET_JIRA_SERVER_OAUTH_STATUS,
      authorized: jiraServers.find((s) => s.id === serverId).authorized
    })
  }

  React.useEffect(() => {
    if (jiraServers !== prevPropsRef.current && jiraServers !== null && jiraServers.length > 0)
      onServerSelected(jiraServers[0].id)
  }, [])

  React.useEffect(() => {
    if (selectedDate !== null && getAllProjects(projects).length === 1)
      setSelectedProjectId(getAllProjects(projects)[0].value)
    else setSelectedProjectId(null)
  }, [selectedDate])

  React.useEffect(() => {
    if (selectedProjectId !== null && getAllProjects(projects).length === 1)
      dispatch(reportsActions.loadReport(token, selectedDate, selectedProjectId))
  }, [selectedProjectId])

  const handleReportSubmit = (e) => {
    e.preventDefault()
    dispatch(reportsActions.sendReportPreview(token, report, setIsPopupOpen, setPopupData))
  }

  const handleSendReport = () => {
    dispatch(reportsActions.saveReport(token, selectedDate, selectedProjectId, report, plan))
    setIsPopupOpen(false)
    setPopupData(null)
  }

  const getAllDates = () => {
    return dates.map((date) => {
      return { value: date, label: formatStringDate(date) }
    })
  }

  const onDateSelected = (date) => {
    setSelectedDate(date)
    dispatch({ type: actionTypes.SET_REPORT, report: '' })
    dispatch({ type: actionTypes.SET_PLAN, plan: '' })
    if (isSaved)
      dispatch({ type: actionTypes.REMOVE_REPORT_ISSAVED })
    if (selectedProjectId !== null)
      dispatch(reportsActions.loadReport(token, date, selectedProjectId))
  }

  const onProjectSelected = (projectId) => {
    setSelectedProjectId(projectId)
    if (isSaved)
      dispatch({ type: actionTypes.REMOVE_REPORT_ISSAVED })
    dispatch(reportsActions.loadReport(token, selectedDate, projectId))
  }

  React.useEffect(() => {
    if (selectedDate !== null && getAllProjects(projects).length === 1)
      setSelectedProjectId(getAllProjects(projects)[0].value)
    else setSelectedProjectId(null)
  }, [selectedDate])

  const handleInput = (event) => {
    if (event.target.getAttribute('name') === 'report')
      dispatch({ type: actionTypes.SET_REPORT, report: event.target.value })
    else
      dispatch({ type: actionTypes.SET_PLAN, plan: event.target.value })
  }

  React.useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.REMOVE_REPORT_ISSAVED })
    }
  }, [])

  return (
    <Container maxWidth="sm">
      <div className="report-main">
        <form className="report-form" onSubmit={(e) => handleReportSubmit(e)}>
          <Select
            value={selectedDate === null
              ? null
              : {
                value: selectedDate,
                label: formatStringDate(selectedDate)
              }
            }
            placeholder={<FormattedMessage defaultMessage='Выбери дату отчета...' id="QXGcGY" />}
            className='font-for-select'
            classNamePrefix='font-for-select'
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            name="Дата отчёта"
            options={getAllDates()}
            onChange={(selected) => onDateSelected(selected.value)}
          />
          {selectedDate !== null && (
            <div className="report-project-select-block">
              <Select
                value={getAllProjects(projects).length === 1 ? getAllProjects(projects)[0] : selectedProjectId === null ? null : {
                  value: selectedProjectId,
                  label: projects.find((p) => p.id === selectedProjectId).name
                }}
                defaultValuePosition="first"
                className='font-for-select'
                classNamePrefix='font-for-select'
                placeholder={<FormattedMessage defaultMessage='Выбери проект' id="ZUN013" />}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="Проект"
                options={getAllProjects(projects)}
                onChange={(selected) => onProjectSelected(selected.value)}
              />
              {selectedProjectId !== null && (
                <React.Fragment>
                  <div className="report-project-select-block">
                    <Select
                      isSearchable={false}
                      value={
                        selectedJiraServerId === null
                          ? null
                          : {
                            value: selectedJiraServerId,
                            label: jiraServers.find((s) => s.id === selectedJiraServerId).jira_url
                          }
                      }
                      className='font-for-select'
                      classNamePrefix='font-for-select'
                      placeholder={<FormattedMessage defaultMessage='Выбор сервера из Jira' id="vOBws/" />}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      name="Сервер"
                      options={getAllServers(jiraServers)}
                      onChange={(selected) => onServerSelected(selected.value)}
                    />
                  </div>
                  {selectedJiraServerId !== null && (
                      <React.Fragment>
                        <Button
                            block
                            color="success"
                            outline
                            className="report__load-from-jira"
                            onClick={() => {
                              if (jiraState === "awaiting_oauth") 
                                dispatch(reportsActions.loadReportInfo(token, true, selectedJiraServerId))
                              else
                                dispatch(reportsActions.getJiraWorklogs(token, selectedDate, selectedJiraServerId))
                            }}
                            disabled={jiraState === "loading"}
                        >
                          {jiraState === "awaiting_oauth" ? (
                              <FormattedMessage defaultMessage='Confirm oauth success' id="S/xzdV" />
                          ) : jiraState === "loading" ? (
                              <FormattedMessage defaultMessage='Заполняем...' id="p1vGVw" />
                          ) : jiraServerAuthorized ? (
                              <FormattedMessage defaultMessage='Заполнить из JIRA' id="WfHkLz" />
                          ) : (
                              <FormattedMessage defaultMessage='Authorize in Jira' id="b6lrCk" />
                          )}
                        </Button>
                      </React.Fragment>
                  )}

                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="report"
                    label={<FormattedMessage defaultMessage='Отчет' id="haOWix" />}
                    value={report}
                    onChange={handleInput}
                    multiline
                    required
                    fullWidth
                    autoFocus
                    className='font-for-select'
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="plan"
                    label={<FormattedMessage defaultMessage='План' id="cYYpi3" />}
                    value={plan}
                    onChange={handleInput}
                    required
                    fullWidth
                    multiline
                    className='font-for-select'
                  />
                  <Button
                    type="submit"
                    block
                    color="primary"
                    className="report__save-button"
                    disabled={isFetching && jiraState !== "loading"}
                  >
                    {isFetching && jiraState !== "loading" ? (
                      <FormattedMessage defaultMessage='Сохраняем...' id="W/MRGf" />
                    ) : (
                      <FormattedMessage defaultMessage='Сохранить' id="SMzCPW" />
                    )}
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}
        </form>
          {error && (
            <Alert block color="danger" className="report__error">
              {error.split('\n').length === 1 ? (
                error
              ) : (
                <ul>
                  {error.split('\n').map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              )}
            </Alert>
          )}
      </div>
      {popupData && (
        <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>
            <FormattedMessage defaultMessage='Вы уверены, что хотите записть следующие задачи?' id="6mpIBW" />
          </DialogTitle>
          <DialogContent>
            <p><strong><FormattedMessage defaultMessage='Дата' id="3G8RXK" />:</strong> {selectedDate}</p>
            <p>
              <strong>
                <FormattedMessage defaultMessage='Проект' id="I/VWY5" />:
              </strong> {projects.find((p) => p.id === selectedProjectId)?.name}
            </p>
            <ul>
              {popupData.tasks.map((task, index) => (
                <li key={index}>
                  <strong>{task.name}</strong>: {task.time_spent_td}
                </li>
              ))}
            </ul>
            <p><strong><FormattedMessage defaultMessage='Всего часов' id="VbcKuN" />:</strong> {popupData.hours_total}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsPopupOpen(false)} color="danger">
              <FormattedMessage defaultMessage='Отмена' id="z32Qwr" />
            </Button>
            <Button onClick={handleSendReport} color="success">
              <FormattedMessage defaultMessage='Сохранить' id="SMzCPW" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  )
})

export default Report