import React from 'react'

import { getDateOptions } from './formatDate'

import { SelectedLabel } from '@components/main/components/SelectedLabel'

export const getProjectToOption = (project) => {
  if (project === null)
    return null
  return {
    value: project.id,
    label: project.name
  }
}

export const getEmployeeToOption = (employee) => {
  if (employee === null)
    return null
  return {
    value: employee.id,
    label:
      employee.full_name.trim().length > 0
        ? employee.full_name
        : employee.ldap_username
  }
}

export const getMatrixRoomValue = (chatRoom) => {
  if (chatRoom === null || typeof chatRoom === 'undefined')
    return null
  return {
    value: chatRoom.id,
    label: chatRoom.name
  }
}

export const getTechnologiesSelected = (value) => {
  return value === null || !value
    ? []
    : value && value.map((entry) => {
      return {
        id: entry['value'],
        name: entry['label']
      }
    })
}

export const getTechnologies = (value) => {
  return value === null || !value
    ? []
    : value && value.map((entry) => {
      let name
      if (entry['title'])
        name = entry['title']
      else
        name = entry['name']
      return {
        value: entry['id'],
        label: name
      }
    })
}

export const getTechIds = (selectedTechnologies) => {
  return selectedTechnologies?.length > 0
    ? selectedTechnologies.map((tech) => tech.id)
    : null
}

export const getAllProjects = (value, component, isFor) => {
  return value === null || (value && value.length === 0) || !value
    ? []
    : value && value.map((entry) => {
      return {
        value: entry['id'],
        label:
          component ? (
            <SelectedLabel entry={entry} isFor={isFor} />
          ) : entry['name'] || entry['full_name']
      }
    })
}

export const getAllServers = (value) => {
  return value && value.map((server) => {
    return {
      value: server.id,
      label: server.jira_url
    }
  })
}

export const getWeeks = (value) => {
  return value && value.map((week) => {
    let date = new Date(week.id)
    date.setDate(date.getDate() + 6)
    return {
      value: week.id,
      label: `${getDateOptions(week.id)} - ${getDateOptions(date)}`
    }
  })
}
